import Stars from "./../components/stars";
import SearchFilter from "../components/search-filter";
import TrackViewItemList from '../../../shared/js/track-view-item-list';
import Echo from "laravel-echo";
import Helpers from "../../../shared/helpers";

Vue.component('page-search', {

    components: {Stars},

    mixins: [SearchFilter, TrackViewItemList, Helpers],

    data: function () {
        return {
            pageType: 'APR',
            isLoading: true,
            airport: {},
            dates: {},
            days_between: 0,

            token: apr.search_token,
            more_coming: apr.more_coming,

            search: [],

            email: null,
            emailErrorMessage: null,
            emailSending: false,
            emailOptIn: true,

            apr_results: 0,
            apr_filtered: 0,
            psf_results: 0,

            markers: [],

            filter_list: [],
            filter_options: [],
            sort_option: 'position-asc',

            error_dialog: {
                show: false,
                bold: `${apr.soldout} is <span class="highlight">sold out.</span>`,
                normal: 'Lots are selling out quick for your travel dates.<br/>Take a look at some other great options',
                excerpt: 'Here are some other great options.'
            },
            displaySavings: apr.displaySavings,
            savingsType: apr.savingsType,
            savingsAmount: apr.savingsAmount,
            possibleSavings: apr.possibleSavings,
	        savingsWorking: false,
        }
    },

    computed: {
        soldout() {
            return _.filter(apr.search_apr.soldout, (item) => {
                return _.findIndex(this.search, ['id', item.id]) === -1;
            });
        },
        apr_soldout_results() {
            return this.soldout.length;
        },
        search_sorted() {
            return _.sortBy(this.search, 'position');
        },
         apr_soldout_value() {
            if (!this.apr_soldout_results || !this.apr_results) {
                return 0;
            }
            return this.apr_soldout_results / (this.apr_soldout_results + this.apr_results);
        },
        topPosition() {
            const filterItemsCount = this.filter_list.filter((filter) => filter != null && filter != 'Hotel').length;
            return `top: calc(100% - ${filterItemsCount * 48 + 140}px);`;
        }
    },

    created() {
        this.initPusher();
    },

    mounted() {
        if (this.more_coming) {
            this.reloadResultsFromServer();
        }

    	this.initCouponModal()

        // filter functions
        this.detectHideFilter();
        this.selectSortByOption();

        this.airport = apr.search_apr.airport
        this.dates = {
            checkindate: apr.search_apr.search.data.checkindate.date_format_4,
            checkoutdate: apr.search_apr.search.data.checkoutdate.date_format_4,
        }

        this.days_between = apr.search_apr.search.data.days;

        this.apr_results = apr.search_apr.meta.apr_count
        this.psf_results = apr.search_apr.meta.psf_count
        this.filter_list = apr.search_apr.meta.options

        this.filterProducts()
        this.showMap()

        if (apr.soldout) {
            this.error_dialog.show = true
        }

        if(apr.search_apr.meta.tweak) {
            dataLayer.push({'event':'GaEvent', 'EventCategory':'Search Tweaks', 'EventAction': `${this.airport.code} Tweaked`, 'EventLabel' : `${this.airport.code} ${apr.search_apr.meta.tweak}`});
        }

        let results = this.search

        _.map(results, function(result) {
            result.distance = parseFloat(result.distance)
        });

        this.search = results

        let label = apr.sort == 'rate-asc' ? apr.sort : 'control'
        dataLayer.push({
            'event': 'GaEvent',
            'EventCategory': 'Search Results Sorting',
            'EventAction': `${this.airport.code} ${label}`,
        });

        if (!this.more_coming) {
            this.trackViewItemList();
        }

        this.listen();
    },

    methods: {
        initPusher: function () {
            window.Echo = new Echo({
                broadcaster: 'pusher',
                key: window.apr.pusher_key,
                cluster: window.apr.pusher_cluster,
                encrypted: true
            });
        },

        applySavings(amount) {
	        this.savingsWorking = true;
            this.$http.get(`/ping?feature=savings&value=${amount}`).then((response) => {
                dataLayer.push({
                    'event': 'GaEvent',
                    'EventCategory': 'Instant Special',
                    'EventAction': 'clicked',
                });
                location.reload();
            }, (response) => {
	            this.savingsWorking = false;
                // alert('Sorry we could not get that page for you')
            })
        },

		showEmailModal(){
			$('#Email_Modal').modal('show')
            dataLayer.push({'event': 'GaEvent', 'EventCategory':'Saved Search', 'EventAction': 'Modal - Opened', 'EventLabel': ''})
        },

		sendSearchResults(){
			let email_regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if(email_regex.test(this.email)){
				this.emailSending = true
                this.$http.post(apr.saved_search_url, {'email_address': this.email, 'token': apr.search_apr.search.data.token, 'optin': this.emailOptIn }).then((response) => {
                    this.emailErrorMessage = null
                    this.email = null
					this.emailSending = false
                    dataLayer.push({'event': 'GaEvent', 'EventCategory':'Saved Search', 'EventAction': 'Modal - Submitted', 'EventLabel': ''})
                    $('#Email_Modal').modal('hide')
					swal({
						title: "Email Sent!",
						type: "success",
						timer: 2000,
						showConfirmButton: false
					});
                }, (response) => {
                    this.emailErrorMessage = 'Please supply a valid email address'
                })
			}else{
                this.emailErrorMessage = 'Please supply a valid email address'
			}
		},

        hideEmailModal() {
            this.emailErrorMessage = null
            this.email = null
            dataLayer.push({'event': 'GaEvent', 'EventCategory':'Saved Search', 'EventAction': 'Modal - Closed', 'EventLabel': ''})
            $('#Email_Modal').modal('hide')
        },

        productClick(lot, index) {
            if (lot.provider === 'APR') {
                this.ecommerceTracking(lot, index);
                return;
            } else {
                window.location.href = `${apr.app_url}/psf/${lot.slug}?source=apr`
            }
        },

        soldOutClicked(lot, index){
		    alert('Sorry, this lot is sold out! Please select another lot or choose different dates.')
        },

        filterProducts() {
            // only APR

            let self = this

            this.apr_filtered = 0;

            if (this.filter_options.length > 0) {
				this.search = _.filter(apr.search_apr.results, function (o) {
                    if (o.provider == 'APR') {
                        let result = false

                        for (var i = 0, len = self.filter_options.length; i < len; i++) {
                            if (o.options.indexOf(self.filter_options[i]) > -1) {
                                result = true
                            }else{
                                self.apr_filtered++;
                            }
                        }
                        return result
                    }

                    if (o.provider == 'PSF') {
                        return true
                    }
                })
            } else {
                this.search = apr.search_apr.results
            }

            this.search = _.uniqBy(this.search, (result) => {
                if (result.provider === 'PSF') {
                    return result.hotel_id;
                }

                return result.id;
            });

            this.sortList()
        },

        sortList() {
            let [type, direction] = this.sort_option.split("-")

            if (type == 'rating') {
                this.search = _.orderBy(this.search, function (o) {
                    if(o.provider === 'APR') {
                        return o.ratings.rating
                    }else{
                        return 0
                    }
                }, direction)

            } else if (type == 'distance') {
                this.search = _.orderBy(this.search, [type], direction)

            } else if (type == 'rate') {
                let [lots, hotels] = _.partition(this.search, ['provider', 'APR'])

                this.search = _.orderBy(this.search, [type], direction)
            } else {
                this.search = _.orderBy(this.search, ['position'], direction)
            }
        },

        showMap(){
            let self = this
            let markerArray = [];

            $(document).on('shown.bs.modal', '#map-modal', function () {
                $("#map_canvas").gmap3({
                    scrollwheel: false,
                    center: [Number(self.airport.geo.latitude), Number(self.airport.geo.longitude)],
                    zoom: 12,
                    mapTypeId: google.maps.MapTypeId.ROADMAP,
                    mapTypeControl: false,
                    navigationControl: true,
                    streetViewControl: true,
                    streetViewControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                    },
                    zoomControlOptions: {
                        position: google.maps.ControlPosition.LEFT_TOP,
                        style: "SMALL"
                    },
                    options: {
                        styles: [
                            {featureType: "poi", elementType: "labels", stylers: [{"visibility": "off"}]}
                        ]
                    }

                })
                    .marker(self.getMarkers())
                    .then(function (generatedMarkers) {
                        markerArray = generatedMarkers; // save markers for future deletion
                    })
                    .on('click', function (marker) {
                        $("#map_marker").empty();
                        let card = $('*[data-lotid="' + marker.id + '"]').clone();
                        card[0].id = 'map_marker_card';
                        $("#map_marker").html(card);

                        document.getElementById('map_marker_card').addEventListener("click", () => {
                            self.productClick(marker, 0);
                        });
                    })
                    .fit();
            });

            // remove all markers
            $(document).on('hidden.bs.modal', '#map-modal', function () {
                for (var i = 0; i < markerArray.length; i++) {
                    markerArray[i].setMap(null);
                }
                $("#map_marker").empty();
            });

            return false;
        },

        notifySearchboxOpen(){
		   console.log('Searchbox Open')
        },

        getMarkers() {
            this.filterProducts()

            this.markers = []
            this.getAirportMarker()

            for (let lot of this.search) {
                let marker = this.getLotMarker(lot);
                this.markers.push(marker)
            }

            return this.markers;
        },

        getAirportMarker() {
            this.markers = [{
	            type: this.airport.type === 'CruisePort' ? 'cruise' : 'airport',
                position: {lat: Number(this.airport.geo.latitude), lng: Number(this.airport.geo.longitude)},
                icon: {
	                url: apr.storage_url + '/img/icons/' + (this.airport.type === 'CruisePort' ? 'cruise-pin-new-grey.png' : 'airport-pin-new-black.png'),
					scaledSize: new google.maps.Size(45, 36),
					anchor: new google.maps.Point(23, 36)
				},
                animation: google.maps.Animation.FADE,
                draggable: false
            }]
        },

        getLotMarker(lot) {
            let props = {
                position: {lat: Number(lot.geo.latitude), lng: Number(lot.geo.longitude)},
                animation: google.maps.Animation.FADE,
                draggable: false,
                flat: true,

                icon: {
                    // url: apr.storage_url + (lot.provider === 'APR' ? '/img/icons/lot-new-pin.png' : '/img/icons/hotel-blank-pin-new.png'),
                    url: lot.provider === 'APR' ? apr.storage_url + '/img/icons/lot-new-pin.png' : 'https://s3.amazonaws.com/apr_cdn/img/icons/hotel-blank-pin-new.png',
                    scaledSize: new google.maps.Size(45, 36),
                    anchor: new google.maps.Point(23, 36),
                    labelOrigin: new google.maps.Point(22, 14)
                },
                label: {
                    text: "$" + Math.round(lot.rate),
                    color: 'white',
                    fontSize: '0.75rem'
                },
                id: lot.id ? lot.id : lot.hotel_id,
                slug: lot.content ? lot.content.slug : lot.slug,
                content: {
                    slug: lot.content ? lot.content.slug : lot.slug,
                },
                provider: lot.provider,
                name: lot.name,
                rate: lot.rate,
            };

            //console.log(props)
            return props;
        },

        getDiscountPercentage(original, discounted){
		    let value = (original/discounted) * 100
            value = (value - 100) * -1
            return Math.round(value)
        },

	    initCouponModal(){
		    if(this.displaySavings && !this.savingsAmount) {
			    setTimeout(() => {
				    $('#SearchCouponModal').modal('show')
			    }, 5000) // 5 seconds
		    }
	    },

        ecommerceTracking(lot, index) {
            //dataLayer - productClick
            let providerName = _.get(lot, 'provider');
            let category = this.getLotCategory(providerName);
            let price = typeof(lot.rate) != "undefined"
                ? lot.rate
                : "";
            let variant = typeof(lot.available) != "undefined" && lot.available === true
                ? "available"
                : "sold out";
            dataLayer.push({
                'event': 'productClick',
                'ecommerce': {
                    'click': {
                        'actionField': {'list': 'Search Results'},
                        'products': [{
                            'name': `${this.airport.code} ${lot.name}`,
                            'id': this.airport.code,
                            'price': price,
                            'brand': '',
                            'category': category,
                            'variant': variant,
                            'position': index
                        }]
                    }
                },
                'eventCallback': function () {
					console.log(`Going to ${lot.content.slug}`)
                    window.location.href = `/${lot.content.slug}`
                }
            })
        },

        listen() {
            let channel = APR_PUSHER_SEARCH_CHANNEL.replace('%s', this.token);

            if (this.more_coming) {
                window.onbeforeunload = function () {
                    return 'Searching for the best parking deals available...';
                };
            }

            window.Echo.channel(channel)
                .listen(window.APR_PUSHER_SEARCH_RESULTS_UPDATED_EVENT, data => {
                    this.appendSearchResult(_.get(data, 'result'));
                })
                .listen(window.APR_PUSHER_SEARCH_META_UPDATED_EVENT, data => {
                    apr.search_apr.meta.options = _.get(data, 'meta.data.options');
                    this.filter_list = _.get(data, 'meta.data.options');
                })
                .listen(window.APR_PUSHER_SEARCH_STATUS_UPDATED_EVENT, data => {
                    // Search was updated somewhere else
                    if (!this.more_coming) {
                        location.reload();
                    }

                    window.onbeforeunload = null;
                    this.more_coming = false;
                    this.pageLoaded = true;

                    this.trackViewItemList();
                });

            window.Echo.connector.pusher.connection.bind('connected', () => {
                this.$http.post(apr.search_perform_delayed_url);
            });
        },

        appendSearchResult(result) {
            apr.search_apr.results.push(result);

            switch (_.get(result, 'provider')) {
                case 'APR':
                    this.apr_results++;
                    break;
                case 'PSF':
                    this.psf_results++;
                    break;
            }

            this.filterProducts();
        },

        reloadResultsFromServer() {
            this.$http.get(apr.search_results_url + '?r=' + Math.random()).then((response) => {
                this.more_coming = _.get(response, 'body.more_coming')
                if (!this.more_coming) {
                    window.onbeforeunload = null;
                }
                _.each(_.get(response, 'body.search_apr', []), (result) => {
                    this.appendSearchResult(result)
                })
            })
        },
    }
});
