var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
		day: true,
		'is-today': _vm.isToday(_vm.day),
		'is-between': _vm.isBetweenDates(_vm.day),
		'is-selected': _vm.selected,
		'is-preselected': _vm.preSelected,
		'rounded': _vm.selected && _vm.$parent.$parent.$parent.$parent.singleDate,
		'disabled': _vm.isBeforeToday(_vm.day) || _vm.isAfterDaysOfSelection(_vm.day) || _vm.isBeforeMinDate(_vm.day) || _vm.isAfterMaxDate(_vm.day),
		'hide': _vm.isNotMonth(_vm.day)
	},on:{"click":function($event){return _vm.handleClick(_vm.day)}}},[_vm._v("\n\t"+_vm._s(_vm.day.getDate())+"\n")])}
var staticRenderFns = []

export { render, staticRenderFns }